<template>
  <div data-cy="order-checkout">
    <template
      v-if="showOrderSummary"
    >
      <v-card-text>
        <material-order-summary-table
          :order-summary="order.summary"
          data-cy="order-summary-table"
        />
      </v-card-text>
      <v-divider />
    </template>
    <v-card-text>
      <material-billing-details-card
        :client-id="clientId"
        :invoice-to-partner="invoiceToPartner"
      />
    </v-card-text>
    <v-divider />
    <v-card-text>
      <material-order-calculoid
        :order-id="order.guid"
        :data="order.calculoidData"
        :client-id="clientId"
      />
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    invoiceToPartner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showOrderSummary () {
      return this.order.summary && (this.order.summary.plugins ||
      this.order.summary.additionalRows ||
      this.order.summary.totalPrice ||
      this.order.summary.totalPriceWithVAT)
    }
  }
}
</script>
